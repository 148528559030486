import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
// import { useSiteMetadata } from "../hooks/use-site-metadata"

import NavMenu from "./navmenu"
// import HeaderSocial from "./header-social"

export default () => (
  <header className="site-header">
    <div className="logo">
      <AnchorLink to="/#top">Revelry</AnchorLink>
    </div>

    <NavMenu />
  </header>
)
