import React from "react"
// import { AnchorLink } from "gatsby-plugin-anchor-links"
// import { useSiteMetadata } from "../hooks/use-site-metadata"
// import scrollTo from "gatsby-plugin-smoothscroll"

export default () => {
  // const { social, footer, address, contacts } = useSiteMetadata()
  return (
    <footer>
      <div className="row">
        <div className="six columns info">

          <div className="footer-logo">
            Revelry
          </div>

          {/* <p>{ footer.text }</p> */}

        </div>

        <div className="six columns right-cols">
          <div className="row">
            {/* <div className="columns">
              <FontAwesomeIcon icon={ faMapMarkerAlt } />
              <h3 className="address">Come Visit</h3>
              <p>
                { address.line1 }<br />
                { address.line2 }<br />
                { address.line3 }
             </p>
           </div> */}

         {/* <div className="columns">
           <FontAwesomeIcon icon={ faUsers } />
           <h3 className="social">socialize</h3>
           <ul>
             { social.map(mySocial =>
               <li><a href={ mySocial.url }>
                 { mySocial.service }
               </a></li>
             )}
           </ul>
        </div> */}

          <div className="columns last">
              <a href="https://dash.revelryapp.com"><strong>Get Started</strong></a>
             {/* <h3 className="contact">Contact Us</h3>
             <ul>
               { contacts.map((contact, index) =>
                 <li><a href={ contact.url }>{ contact.text }</a></li>
               )}
             </ul> */}
          </div>
        </div>
      </div>

      <p className="copyright">&copy; 2020 Revelry App</p>

      {/* <div id="go-top">
        <button title="Back to Top" onClick={() => scrollTo('#top')}>
          <FontAwesomeIcon icon={ faChevronUp } />
        </button>
      </div> */}
    </div>
  </footer>

)}
